import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import {
  InvoiceError,
  InvoiceStat,
  SalesInvoice,
  SalesInvoiceDraft,
  SalesInvoicePaymentStatus,
} from '@dougs/sales-invoice/dto';

@Injectable({
  providedIn: 'root',
})
export class SalesInvoiceHttpService {
  constructor(private readonly http: HttpClient) {}

  getStats(companyId: number | string): Observable<InvoiceStat> {
    return this.http.get<InvoiceStat>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/invoicer/stats`);
  }

  getSalesInvoices(
    companyId: number,
    status: SalesInvoicePaymentStatus | null,
    offset = 0,
    limit = 20,
  ): Observable<SalesInvoice[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('limit', limit).append('offset', offset * limit);
    if (status) {
      params = params.append('paymentStatus', status);
    }
    return this.http.get<SalesInvoice[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices`,
      {
        params,
      },
    );
  }

  getSalesInvoicesDraft(companyId: number, offset = 0, limit = 20): Observable<SalesInvoiceDraft[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('limit', limit).append('offset', offset * limit);
    return this.http.get<SalesInvoiceDraft[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices-drafts`,
      { params },
    );
  }

  getSalesInvoice(companyId: number, invoiceId: string): Observable<SalesInvoice> {
    return this.http.get<SalesInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices/${invoiceId}`,
    );
  }

  getSalesInvoiceDraft(companyId: number | string, invoiceId: string): Observable<SalesInvoiceDraft> {
    return this.http.get<SalesInvoiceDraft>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices-drafts/${invoiceId}`,
    );
  }

  updateSalesInvoice(updatedInvoice: SalesInvoice): Observable<SalesInvoice> {
    return this.http.post<SalesInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${updatedInvoice.companyId}/sales-invoices/${updatedInvoice.id}`,
      updatedInvoice,
    );
  }

  updateSalesInvoiceDraft(updatedInvoiceDraft: SalesInvoiceDraft): Observable<SalesInvoiceDraft> {
    return this.http.post<SalesInvoiceDraft>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${updatedInvoiceDraft.companyId}/sales-invoices-drafts/${updatedInvoiceDraft.id}`,
      updatedInvoiceDraft,
    );
  }

  duplicateSalesInvoice(companyId: number | string, invoiceId: number | string): Observable<SalesInvoiceDraft> {
    return this.http.post<SalesInvoiceDraft>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices/${invoiceId}/actions/duplicate`,
      {},
    );
  }

  duplicateSalesInvoiceDraft(companyId: number | string, invoiceId: number | string): Observable<SalesInvoiceDraft> {
    return this.http.post<SalesInvoiceDraft>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices-drafts/${invoiceId}/actions/duplicate`,
      {},
    );
  }

  uploadLogo(companyId: string | number, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/attachments?filename=${file.name}&type=invoicingLogo`,
      formData,
    );
  }

  createSalesInvoiceDraft(companyId: number): Observable<SalesInvoiceDraft> {
    return this.http.post<SalesInvoiceDraft>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices-drafts`,
      {},
    );
  }

  deleteSalesInvoiceDraft(companyId: number | string, invoiceId: string): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices-drafts/${invoiceId}`,
    );
  }

  canFinalizeOffer(companyId: number | string, invoiceId: number | string): Observable<InvoiceError[]> {
    return this.http.get<InvoiceError[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices-drafts/${invoiceId}/actions/can-finalize-offer`,
    );
  }

  canFinalize(companyId: number | string, invoiceId: string): Observable<InvoiceError[]> {
    return this.http.get<InvoiceError[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices-drafts/${invoiceId}/actions/can-finalize`,
    );
  }

  finalize(companyId: number | string, invoiceId: string): Observable<SalesInvoiceDraft> {
    return this.http.post<SalesInvoiceDraft>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/sales-invoices-drafts/${invoiceId}/actions/finalize`,
      {},
    );
  }

  attachOperation(operationId: number, salesInvoice: SalesInvoice): Observable<SalesInvoice> {
    return this.http.post<SalesInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${salesInvoice.companyId}/sales-invoices/${salesInvoice.id}/actions/attach-operations`,
      { operationIds: [operationId] },
    );
  }

  detachOperation(operationId: number, salesInvoice: SalesInvoice): Observable<SalesInvoice> {
    return this.http.post<SalesInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${salesInvoice.companyId}/sales-invoices/${salesInvoice.id}/actions/detach-operations`,
      { operationIds: [operationId] },
    );
  }

  acceptMatchedOperation(salesInvoice: SalesInvoice): Observable<SalesInvoice> {
    return this.http.post<SalesInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${salesInvoice.companyId}/sales-invoices/${salesInvoice.id}/actions/accept-matched-operation`,
      {},
    );
  }

  rejectMatchedOperation(salesInvoice: SalesInvoice): Observable<SalesInvoice> {
    return this.http.post<SalesInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${salesInvoice.companyId}/sales-invoices/${salesInvoice.id}/actions/reject-matched-operation`,
      {},
    );
  }
}
